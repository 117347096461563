import {ModalBody, VStack} from "@chakra-ui/react";
import React from "react";
import ModalListItem from "./ModalListItem";

type Props = {
    info : string[]
}

function MyModalBody({info}: Props) {
    return (
        <ModalBody>
            <VStack align={'left'}>
                {info
                    .map((value, index) => {
                        return <ModalListItem key={index+"ModalItem"} line={value} index={index+"ModalReplaced"} />
                    })}
            </VStack>
        </ModalBody>
    );
}

export default MyModalBody;
