import {
    Box,
    HStack,
    Image,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text
} from "@chakra-ui/react";
import * as React from "react";
import {useState} from "react";

type Props = {
    sizes: string[]
}
function Donations({sizes}: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };

    return (<>
            <Box borderWidth={'5px'} borderColor={'black'} boxSize={sizes}>
            <Image onClick={handleOpenModal} cursor="pointer" objectFit='contain' marginLeft="auto"
                    src={'/swish-QR.png'} alt={'Swish QR'} borderWidth={'30px'} borderColor={'black'}></Image>
    </Box>

            <Modal isOpen={isOpen} onClose={handleCloseModal}>
                <ModalOverlay/>
                <ModalContent>
                    <ModalCloseButton/>
                    <ModalBody justifyContent={'center'}>
                        <Image src="/swish-QR.png" alt="Swish QR"/>
                        <Text fontSize='xs' align={'left'}>Om du vill bidra till mitt arbete att hålla denna sida levande och
                            uppdaterad så tar jag tacksamt emot donationer</Text>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default Donations;
