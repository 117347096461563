export const todayColor = (inputDate: Date | undefined): string => {
    return isToday(inputDate) ? 'black' : 'red'
};

export const isToday = (date: Date | undefined): boolean => {
    if(date === undefined) {
        return false
    }

    const today = new Date();
    return (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() &&
        date.getDate() === today.getDate()
    );
}
