import {Divider, HStack, Link, Spacer, Text, VStack} from "@chakra-ui/react";
import Donations from "./Donations";
import React from "react";
import InfoButton from "./InfoButton";
import Contact from "./Contact";

type Props = {
    updated: Date | undefined
}
function InfoButtons({updated}: Props) {
    const sizes = ['50px','75px'];
    return <>
        <VStack>
        <HStack alignItems={'left'}>
            <Donations sizes={sizes}/>
            <InfoButton sizes={sizes} updated={updated}/>
            <Contact sizes={sizes}></Contact>
        </HStack>
        <HStack>
            <> <Link href={'https://apotek.varden.se/'}><Text noOfLines={1}>Apoteks Kollen</Text></Link>  <Text> | </Text> </>
            <>  <Link href={'https://www.fass.se/LIF/startpage?userType=0'}>FASS</Link> <Text> | </Text></>
            <Link href={'https://www.lakemedelsverket.se/'}>Läkemedelsverket</Link>
        </HStack>
        </VStack>
    </>
}

export default InfoButtons;
