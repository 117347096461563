import * as React from "react";
import {Box, Flex, Input, Text, VStack} from "@chakra-ui/react";

type Props = {
    setQuery: (query: string) => void,
}
function Search({setQuery} : Props) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value)
    }

  return (
      <Box w='80%' p={4} color='black' borderWidth='1px' borderRadius='lg'>
              <Input w={'100%'} marginBottom={"15px"} placeholder='Namn på läkemedel'
                     onChange={handleChange}/>
      </Box>
  );
}
export default Search;
