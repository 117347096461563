
import {
    Divider,
    Heading, Popover,
    PopoverArrow, PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger, Text, VStack
} from "@chakra-ui/react";
import {QuestionIcon} from "@chakra-ui/icons";
import React from "react";
import {Company, MedicineInfo} from "../../generated/Medicines";

type Props = {
    info : MedicineInfo
    company: Company | undefined
}

function Information({info, company}: Props) {

    return (<Popover>
        <PopoverTrigger>
            <QuestionIcon cursor={'pointer'}></QuestionIcon>
        </PopoverTrigger>
        <PopoverContent minW={{ base: "100%", lg: "max-content" }}>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>
                <Heading as='h5' size='sm'>
                    Information
                </Heading>
            </PopoverHeader>
            <PopoverBody>
                <VStack align={'left'}>
                    <Text>Aktiva substanser: {info.activeSubstance}</Text>
                    <Text>ATC kod: {info.ATC}</Text>
                    <Divider />
                    <Text>Företag:</Text>
                    <Text>{company?.companyName}</Text>
                    <Text>{company?.phone}</Text>
                    <Text>{company?.email}</Text>
                </VStack>
            </PopoverBody>
        </PopoverContent>
    </Popover>);
}

export default Information
