import React from "react";
import {Medicine} from "../../generated/Medicines";
import {Card, CardBody, CardHeader, GridItem, Heading, HStack, Spacer, Text, VStack} from "@chakra-ui/react";
import RenderIfVisible from "react-render-if-visible";
import Information from "./Information";
import MoreInfo from "./MoreInfo";

export class MedicineCard extends React.Component {

    private readonly medicine: Medicine;
    private readonly card: JSX.Element;

    constructor(medicine: Medicine) {
        super(medicine);
        this.medicine = medicine;
        this.card = this.createCard();
    }


    public render() {
        return <RenderIfVisible key={this.medicine.rowNumber}>
            <GridItem w='100%'>{this.card}</GridItem>
        </RenderIfVisible>
    }

    public shouldRender(query: String): boolean {
        return query === "" || this.medicine.medicineInfo === undefined
            ? true
            : this.medicine.medicineInfo.name.toLowerCase().includes(query.toLowerCase());
    }

    createCard() {
        return (
            <Card h='100%' key={this.medicine.rowNumber + (this.medicine.medicineInfo?.name ?? "")}
                  paddingBottom={'10px'}>
                <CardHeader>

                    <VStack align={'left'} w='100%'>
                        <HStack>

                            <Heading as='h5' size='sm'>{this.medicine.medicineInfo?.name}</Heading>
                            <Text> {this.medicine.status}</Text>

                            <Spacer/>
                            <Information info={this.medicine.medicineInfo!} company={this.medicine.company}></Information>
                        </HStack>
                        <Text
                            as='i'>{this.medicine.prognosis?.start?.toISOString().split("T")[0] + " - " + (this.medicine.prognosis?.end?.toISOString().split("T")[0] ?? "")}
                        </Text>

                    </VStack>


                </CardHeader>
                <CardBody>
                    <VStack align={'left'}>
                        <MoreInfo info={this.medicine.info} medInfo={this.medicine.medicineInfo} varunummer={this.medicine.varunummer}></MoreInfo>
                        <Text noOfLines={1}>{this.medicine.medicineInfo?.packaging}</Text>
                        <Text>{replaceEmpty(this.medicine.medicineInfo?.strength)}</Text>
                    </VStack>
                </CardBody>
            </Card>
        )
    }
}

function replaceEmpty(value : string | undefined) : string{
    if(!value) {
        return "-";
    }
    return value;
}


