import * as React from "react"
import {useEffect, useState} from "react"
import {Box, Center, ChakraProvider, Spinner, Text, theme, VStack} from "@chakra-ui/react"

import MedicineGrid from "./grid/MedicineGrid";

import {Medicine, Medicines} from "./generated/Medicines";
import {MedicineCard} from "./grid/card/MedicineCard";
import Title from "./title/Title";
import Search from "./search/Search";
import {isToday} from "./Utils";




function AlertBox() {
    return (<Box justifyContent={'center'} marginTop={'10px'} alignContent={'center'} w='80%' color='red' borderColor={'red'} borderWidth='1px' borderRadius='lg'>
        <Center>
        <Text>Informationen är för tillfället inte uppdaterat då Läkemedelseverket har ändrat sitt format. När jag har hunnit uppdatera min kod kommer
        senaste datat vara tillgängligt igen.
        </Text>
        </Center></Box>)
}

export const App = () => {
    useEffect(() => {
        fetch("/medicines.bin")
            .then(p => p.arrayBuffer())
            .then(a => {
                if(a !== undefined) {
                    var uint8View = new Uint8Array(a);

                    const medicines = Medicines.decode(uint8View)
                    const medicineCards = medicines.medicines.map(
                        (m: Medicine) => new MedicineCard(m)
                    );
                    setUpdated(medicines.timestamp);
                    setList(medicineCards);
                    setLoaded(true);
                }
            })
    }, []);




    const [list, setList] = useState<MedicineCard[]>()

    const [query, setQuery] = useState<string>("")

    const [loaded, setLoaded] = useState<boolean>(false)

    const [updated, setUpdated] = useState<Date | undefined>(undefined)




  return (
      <ChakraProvider theme={theme}>
          <VStack spacing='8px'>
              {loaded && !isToday(updated) ? <AlertBox></AlertBox> : <></>}
              <Title updated={updated}></Title>
              <Search setQuery={setQuery}></Search>
              {
                  loaded ?
                      <MedicineGrid list={list} query={query}></MedicineGrid> :
                      <Spinner/>}
          </VStack>
      </ChakraProvider>
  )
}





