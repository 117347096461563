import * as React from "react";
import {Box, Heading, Link, Text, VStack} from "@chakra-ui/react";
import {ExternalLinkIcon, Icon} from "@chakra-ui/icons";
import {GiMedicines} from "react-icons/gi";
import Donations from "./Donations";
import InfoButtons from "./InfoButtons";


type Props = {
  updated: Date | undefined,
}
function Title({updated}: Props) {
  return <>
  <Box w='80%'  alignItems={'left'}>
        <VStack>
            <Heading size={['lg','1xl','2xl','4xl']}><Icon as={GiMedicines}></Icon> Restnoteringar</Heading>
            <InfoButtons updated={updated}/>
        </VStack>
  </Box>

  </>;
}

export default Title;
