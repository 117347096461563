import React from "react";
import {
    Box,
    Button, Link,
    Modal,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import {MedicineInfo} from "../../generated/Medicines";
import MyModalBody from "./modal/MyModalBody";

type Props = {
    info : string[]
    medInfo: MedicineInfo | undefined

    varunummer: string
}

function MoreInfo(props: Props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    return (
        <>
            <Button colorScheme='teal' size='xs' onClick={onOpen} w={'100px'}>Mera</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent minWidth={'80%'}>
                    <ModalHeader>
                        <Box>
                            <VStack align={'left'}>
                                <Text>{props.medInfo?.form}</Text>
                                <Text>{props.medInfo?.packaging}</Text>
                                <Text>{props.medInfo?.strength}</Text>
                            </VStack>
                            <Link textColor={'blue'} href={'https://www.fass.se/LIF/result?userType=0&query=' + props.varunummer} isExternal={true}>Fass</Link>
                        </Box>
                    </ModalHeader>
                    <ModalCloseButton />
                    <MyModalBody info={props.info}/>
                </ModalContent>
            </Modal>
        </>
    )
}

export default MoreInfo;
