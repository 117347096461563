import * as React from "react";
import {Center, SimpleGrid, Text} from "@chakra-ui/react";
import {MedicineCard} from "./card/MedicineCard";


type Props = {
    list: MedicineCard[] | undefined,
    query: string
}

function MedicineGrid(props: Props) {

    if (props.list === undefined || props.list.length === 0) {
        return <Center><Text>Ingen träff</Text></Center>
    }

    return <SimpleGrid w='80%'  minChildWidth='280px' spacing='80px' gap={6}>
        {props.list.filter(card=> card.shouldRender(props.query)).map((value) => value.render()
        )}
        </SimpleGrid>
}


export default MedicineGrid;
