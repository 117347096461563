import {Text, Tooltip} from "@chakra-ui/react";
import reactStringReplace from "react-string-replace";
import {Icon, WarningTwoIcon} from "@chakra-ui/icons";
import React from "react";

type Props = {
    line: string,
    index: string,
    key: string
}

function ModalListItem(props: Props) {
    let replaced = reactStringReplace(props.line, '(Restanmäld)', () => (
        <Tooltip label="Restanmäld" key={props.index}>
            <Icon as={WarningTwoIcon} color='orange.500'/>
        </Tooltip>
    ));

    return (
        <Text>
            {replaced}
        </Text>
    );
}

export default ModalListItem;
