import {GiInfo} from "react-icons/gi";
import {ExternalLinkIcon, Icon} from "@chakra-ui/icons";
import * as React from "react";
import {useState} from "react";
import {
    Divider,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text, HStack
} from "@chakra-ui/react";
import {todayColor} from "../Utils";

type Props = {
    sizes: string[],
    updated: Date | undefined
}

function InfoButton({sizes, updated}: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const handleOpenModal = () => {
        setIsOpen(true);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
    };
    return <>
        <Icon cursor="pointer" onClick={handleOpenModal} boxSize={sizes} as={GiInfo}></Icon>

        <Modal isOpen={isOpen} onClose={handleCloseModal}>
            <ModalOverlay/>
            <ModalContent>
                <ModalCloseButton/>
                <ModalBody justifyContent={'center'}>
                    <Text fontSize='xs' align={'left'} as={'i'}>Denna sida har skapats och drivs av en privatperson,
                        jag gör mitt bästa för att datat ska vara korrekt och uppdaterat</Text>
                    <Divider m={[2, 3]}/>
                    <Text as={'b'} fontSize='xs' align={'left'}>Stort tack till er som donerat och skickat feedback. Om du uppskattar denna sida, sprid den
                    gärna till vänner och kollegor.</Text>
                    <Divider m={[2, 3]}/>
                    <Text fontSize='xs' align={'left'}>
                        Informationen hämtas från{' '}
                        <Link href='https://www.lakemedelsverket.se/sv/behandling-och-forskrivning/forskrivning/restsituationer' isExternal={true}>
                            Läkemedelseverket <ExternalLinkIcon mx='2px' />
                        </Link>
                        <Link href='https://docetp.mpa.se/LMF/Reports/Restanmalningar.xlsx' isExternal>
                            Orginalfil
                            <ExternalLinkIcon mx='2px' />
                        </Link>.
                    </Text>
                    <Divider m={[2, 3]}/>
                    <HStack >
                        <Text fontSize='xs' align={'left'}>
                            Datat uppdaterades senast
                        </Text>
                        <Text color={todayColor(updated)} fontSize='xs' align={'left'}>
                            {updated?.toLocaleDateString('sv-SE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
                        </Text>
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    </>
}



export default InfoButton;
